.user-selector {
  padding: 5px 10px;
  border-radius: 7px;
  transition: .3s;
  cursor: pointer;
  border: 2px rgb(229, 231, 235) dashed;
  &:hover {
    background-color: lightcyan;
  }
  .user-selector-title {
    font-weight: 300;
    font-size: 14px;
  }
  .user-selector-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    h1 {
      font-size: 17px;
      font-weight: 400;
    }
    p {
      margin: auto 0;
      height: fit-content;
    }
  }
}