.inline-notification {
    border-radius: 7px;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    transition: .3s;
    .logo {
        height: 25px;
        svg {
            height: 100%;
            width: auto;
        }
    }
    .info {
        font-size: 15px;
        height: fit-content;
        margin: auto 0;
        p {
        }
    }

}
.notify-error {
    background-color: rgb(253, 237, 237);
    svg {
        color: rgb(211, 47, 47)
    }
}

.notify-info {
    background-color: #fffdde;
    svg {
        color: #ffc800;
    }
}

.notify-success {
    background-color: rgb(237, 247, 237);
    svg {
        color: rgb(30, 70, 32)
    }
}