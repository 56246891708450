.main {
  margin: 80px auto 0px auto;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  gap: 15px;
  .main_container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    .settings {
      width: 400px;
      gap: 15px;
      display: flex;
      flex-direction: column;
      .submit {
        width: 100%;
      }
    }
    .lessons {
      display: flex;
      flex-direction: column;
      text-align: right;
      gap: 15px;
      .lesson {
        width: 500px;
        height: 300px;
        border-radius: 7px;
        overflow: hidden;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .all_lessons_button {
        font-size: 15px;
        border-radius: 7px;
        border: none;
        background-color: #e0beff;
        color: #000000;
        height: 50px;
        font-weight: 400;
        transition: .3s;
        cursor: pointer;
        &:hover {
          background-color: #c293f5;
          cursor: pointer;
        }

      }
    }
  }
}